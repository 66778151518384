import React from "react"
import PropTypes from "prop-types"

const Header = ({ siteTitle }) => (
  <div className='bg-transparent z-20 fixed text-white text-lg'>
    {/* <span>{siteTitle}</span> */}
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
